import { Injectable } from '@angular/core';
import { ShortcutDefinition } from '../domain/shortcut.model';

@Injectable({
  providedIn: 'root'
})
export class VideoKeyboardShortcutService {
  constructor() {}

  createAccentedShortcutMarkup(value: string, shortcut: string): string {
    const regex = new RegExp(shortcut, 'i');
    const replacement = value.replace(regex, (match) => {
      const result =
        match === match.toUpperCase()
          ? shortcut.toUpperCase()
          : shortcut.toLowerCase();
      return `<strong>${result}</strong>`;
    });
    if (replacement === value) {
      // not found: put shortcut in front
      return `<strong>${shortcut}</strong>&nbsp;${value}`;
    } else {
      return replacement;
    }
  }

  verifyShortcuts<T>(shortcuts: ShortcutDefinition<T>[]) {
    const keys = [];
    shortcuts.forEach((s) => {
      if (keys.includes(s.shortcutKey)) {
        throw new Error(`Duplicated shortcut-key '${s.shortcutKey}'`);
      }
      keys.push(s.shortcutKey);
    });
    return true;
  }

  findValueForShortcutKey<T>(shortcuts: ShortcutDefinition<T>[], key: string) {
    const shortcut = this.find(shortcuts, key);
    return shortcut?.value;
  }

  hasShortcutForKey<T>(shortcuts: ShortcutDefinition<T>[], key: string) {
    return !!this.find(shortcuts, key);
  }

  private find<T>(shortcuts: ShortcutDefinition<T>[], key: string) {
    return shortcuts.find((s) => s.shortcutKey === key);
  }
}
