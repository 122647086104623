<mat-chip-grid #chipList aria-label="Tag selection">
  <mat-chip-row
    *ngFor="let tag of selectedTags"
    (removed)="removeTagFilter(tag)"
    style="font-size: 14px; padding: 3px 6px; min-height: 24px"
  >
    {{ tag }}
    <button matChipRemove>
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-chip-row>
  <input
    placeholder="Select tag..."
    class="tag-input-field"
    style=""
    #tagInput
    [formControl]="addTagCtrl"
    [matAutocomplete]="auto"
    [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    (matChipInputTokenEnd)="enterTag($event)"
  />
</mat-chip-grid>
<mat-autocomplete
  #auto="matAutocomplete"
  (optionSelected)="selectTagFromList($event)"
>
  <mat-option
    class="tag"
    *ngFor="let tag of filteredTags | async"
    [value]="tag"
  >
    {{ tag }}
  </mat-option>
</mat-autocomplete>
