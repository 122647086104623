import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { VideoKeyboardShortcutService } from './video-keyboard-shortcut.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video-keyboard-shortcut',
  templateUrl: './video-keyboard-shortcut.component.html',
  styleUrls: ['./video-keyboard-shortcut.component.css']
})
export class VideoKeyboardShortcutComponent implements OnInit {
  @Input()
  private readonly shortcutKey: string;

  @Input()
  private readonly label: string;

  labelWithShortcut: string;

  constructor(
    private videoKeyboardShortcutService: VideoKeyboardShortcutService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    const unsecureMarkup =
      this.videoKeyboardShortcutService.createAccentedShortcutMarkup(
        this.label,
        this.shortcutKey
      );
    const sanitizedMarkup = this.sanitizer.sanitize(
      SecurityContext.HTML,
      unsecureMarkup
    );
    this.labelWithShortcut = sanitizedMarkup;
  }
}
