<button mat-icon-button [matMenuTriggerFor]="menu" style="bottom: 6px">
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="importMenu"
    ><mat-icon>file_upload</mat-icon>Import</button
  >
  <button mat-menu-item [matMenuTriggerFor]="exportMenu"
    ><mat-icon>file_download</mat-icon>Export</button
  >
  <button mat-menu-item [matMenuTriggerFor]="actionsMenu"
    ><mat-icon>build</mat-icon>Other Actions</button
  >
  <button mat-menu-item [matMenuTriggerFor]="liveStreamsMenu"
    ><mat-icon>live_tv</mat-icon>Live Streams</button
  >
  <button mat-menu-item [matMenuTriggerFor]="reportMenu"
    ><mat-icon>summarize</mat-icon>Reports</button
  >

  <button
    mat-menu-item
    *ngIf="game.status === gameStatus.NEW"
    (click)="updateStatus(game, gameStatus.IN_COLLECTION)"
    [disabled]="
      (authService.hasPermission('update-status:games') | async) === false
    "
    ><mat-icon>cached</mat-icon>Start Collection</button
  >
  <button
    mat-menu-item
    *ngIf="
      game.status === gameStatus.IN_COLLECTION &&
      ((game.dataSet &&
        game.dataSet !== 'LITE' &&
        game.dataSet !== 'LITE_PLUS') ||
        !game.dataSet)
    "
    (click)="updateStatus(game, gameStatus.IN_EXTENDED_COLLECTION)"
    [disabled]="
      (authService.hasPermission('update-status:games') | async) === false
    "
    ><mat-icon>cached</mat-icon>Start Extended Collection</button
  >
  <button
    mat-menu-item
    *ngIf="
      game.status === gameStatus.IN_COLLECTION ||
      game.status === gameStatus.IN_EXTENDED_COLLECTION
    "
    (click)="updateStatus(game, gameStatus.QUALITY_CONTROL)"
    [disabled]="
      (authService.hasPermission('update-status:games') | async) === false
    "
    ><mat-icon>face</mat-icon>Start Quality Control</button
  >
  <button
    mat-menu-item
    *ngIf="game.status === gameStatus.QUALITY_CONTROL"
    (click)="updateStatus(game, gameStatus.COMPLETE)"
    [disabled]="
      (authService.hasPermission('update-status:games') | async) === false
    "
    ><mat-icon>check_circle</mat-icon>Collection Complete</button
  >

  <button
    mat-menu-item
    (click)="validateGame(game)"
    [disabled]="(authService.hasPermission('validate:games') | async) === false"
  >
    <mat-icon>check</mat-icon>Validate</button
  >
  <button mat-menu-item (click)="runAggregation(game)">
    <mat-icon>sync</mat-icon>Run Aggregation</button
  >
  <button
    mat-menu-item
    [disabled]="(authService.hasPermission('run:cvpipeline') | async) === false"
    (click)="runCVPipeline(game)"
    ><mat-icon>visibility</mat-icon>Run CV-Pipeline...</button
  >
  <button
    mat-menu-item
    [routerLink]="['/games', game._id]"
    [routerLinkActive]="['current-view']"
    [disabled]="(authService.hasPermission('write:games') | async) === false"
  >
    <mat-icon>edit</mat-icon>Edit Game Setup</button
  >
  <button
    mat-menu-item
    (click)="duplicate(game)"
    [disabled]="(authService.hasPermission('write:games') | async) === false"
    ><mat-icon>content_copy</mat-icon>Duplicate...</button
  >
  <button
    mat-menu-item
    (click)="delete(game)"
    [disabled]="(authService.hasPermission('delete:games') | async) === false"
    ><mat-icon>delete</mat-icon>Delete...</button
  >
</mat-menu>

<mat-menu #exportMenu="matMenu">
  <button
    mat-menu-item
    (click)="syncGame(game)"
    [disabled]="
      (authService.hasPermission('synchronize:games') | async) === false
    "
  >
    <mat-icon>sync</mat-icon>Sync to Cockpit...</button
  >
  <button
    mat-menu-item
    [disabled]="
      (authService.hasPermission('export:events') | async) === false ||
      game.idType !== 'SIHF'
    "
    (click)="sihfExportDialog(game)"
    title="SIHF Export"
    ><mat-icon>file_download</mat-icon>SIHF Export...</button
  >
  <button
    mat-menu-item
    [disabled]="
      (authService.hasPermission('download-csv:events') | async) === false
    "
    (click)="downloadGameEventsCSV(game)"
    title="Download Game Events"
    ><mat-icon>file_download</mat-icon>Download Game Events CSV</button
  >
  <button
    mat-menu-item
    [disabled]="
      (authService.hasPermission('download-csv:events') | async) === false
    "
    (click)="downloadShiftsCSV(game)"
    title="Download Game Events"
    ><mat-icon>file_download</mat-icon>Download Shifts CSV</button
  >

  <button
    mat-menu-item
    matTooltip="Exports goal clips to Vimeo and sends a notification to Opten. Exporting requires the flag 'Goal Clips' on a game and league NL."
    (click)="exportGoalClips(game)"
    [disabled]="game.league !== '1' || !game.goalClips"
    ><mat-icon>sync</mat-icon>Export Goal Clips</button
  >
</mat-menu>

<mat-menu #importMenu="matMenu">
  <button
    mat-menu-item
    [disabled]="(authService.hasPermission('import:events') | async) === false"
    (click)="uploadGameEventsCSV(game)"
    title="Upload Game Events"
    ><mat-icon>file_upload</mat-icon>Upload Game Events CSV...</button
  >

  <button
    mat-menu-item
    [disabled]="
      (authService.hasPermission('import:events') | async) === false ||
      game.idType !== 'SIHF' ||
      !game.gameId
    "
    (click)="importPenaltiesSihfReporter(game._id)"
    title="Import Penalties from SIHF Reporter"
    ><mat-icon>sync</mat-icon>Import Penalties from SIHF</button
  >
  <button
    mat-menu-item
    [disabled]="game.idType !== 'SIHF' || !game.gameId"
    (click)="importGoalsSihfReporter(game._id)"
    title="Import Goals from SIHF Reporter"
    ><mat-icon>sync</mat-icon>Sync Goals with SIHF...</button
  >

  <button
    mat-menu-item
    [disabled]="
      (authService.hasPermission('batch-write:events') | async) === false
    "
    (click)="updateSIHFPeriodEvents(game._id)"
    title="Update video time of all period events for imported SIHF game time."
    ><mat-icon>build</mat-icon>Update video time of SIHF period
    events...</button
  >
</mat-menu>

<mat-menu #actionsMenu="matMenu">
  <button
    mat-menu-item
    [disabled]="
      (authService.hasPermission('batch-write:events') | async) === false
    "
    (click)="applyVideoTimeOffset(game)"
    title="Adjust the videoTime of each event by an offset"
    ><mat-icon>build</mat-icon>Apply VideoTime offset...</button
  >
  <button
    mat-menu-item
    [disabled]="
      (authService.hasPermission('batch-write:events') | async) === false
    "
    (click)="updateGameTimes(game)"
    title="Updates the gameTime of all non-faceoff/non-interruption events by interpolation based on videoTime."
    ><mat-icon>build</mat-icon>Interpolate GameTimes</button
  >
  <button
    mat-menu-item
    [disabled]="
      (authService.hasPermission('batch-write:events') | async) === false
    "
    (click)="updateVideoTimes(game)"
    title="Updates the videoTime of all non-faceoff/non-interruption events by interpolating based on gameTime."
    ><mat-icon>build</mat-icon>Interpolate VideoTimes</button
  >
  <button
    mat-menu-item
    [disabled]="
      (authService.hasPermission('batch-write:events') | async) === false
    "
    (click)="updateStrengthStates(game)"
    title="Derive the strength state of all non-penalty events from penalty events."
    ><mat-icon>build</mat-icon>Adjust Strength States...</button
  >
  <button
    mat-menu-item
    (click)="adjustLineup(game)"
    [disabled]="
      (authService.hasPermission('batch-write:events') | async) === false
    "
    ><mat-icon>build</mat-icon>Adjust Game Lineup...</button
  >
  <button
    mat-menu-item
    (click)="splitShiftsByStrengthState(game)"
    [disabled]="
      (authService.hasPermission('batch-write:events') | async) === false
    "
    ><mat-icon>build</mat-icon>Split Shifts by Strength State...</button
  >
  <button
    mat-menu-item
    [disabled]="
      (authService.hasPermission('batch-write:events') | async) === false
    "
    (click)="recalculateFOINTGameTime(game)"
    title="Recalculates the gameTime of all FOINT events based on videoTime elapsed."
    ><mat-icon>build</mat-icon>Recalculate CV FOINT GameTime...</button
  >
</mat-menu>

<mat-menu #liveStreamsMenu="matMenu">
  <button
    mat-menu-item
    title="Archive NL HLS Videos"
    [disabled]="game.progress !== GameProgress.ENDED"
    (click)="archiveLiveStream(game)"
    ><mat-icon>live_tv</mat-icon>Archive HLS...
  </button>
  <a
    mat-menu-item
    target="_blank"
    [disabled]="!game.finalization"
    [href]="finalizeLiveStreamLogs(game)"
    ><mat-icon>link</mat-icon>Show Logs
  </a>
  <button mat-menu-item (click)="finalizationComplete(game)" title="Finalize"
    ><mat-icon>check</mat-icon>Archiving Complete
  </button>
  <button
    mat-menu-item
    title="Shutdown"
    (click)="manageMediaServer(game, 'shutdown')"
    ><mat-icon>stop</mat-icon>Shutdown Media Server...
  </button>
  <button
    mat-menu-item
    title="Restart"
    (click)="manageMediaServer(game, 'restart')"
    ><mat-icon>stop</mat-icon>Restart Media Server...
  </button>
</mat-menu>

<mat-menu #reportMenu="matMenu">
  <button
    mat-menu-item
    (click)="showGameCollectionEffort(game)"
    [disabled]="(authService.hasPermission('read:effort') | async) === false"
  >
    <mat-icon>bar_chart</mat-icon>Collection Effort</button
  >
  <button
    mat-menu-item
    (click)="showGameCollectionQuality(game)"
    [disabled]="(authService.hasPermission('read:effort') | async) === false"
  >
    <mat-icon>bar_chart</mat-icon>Collection Quality</button
  >
  <button
    mat-menu-item
    (click)="showTimeOnIceQualityReport(game)"
    [disabled]="(authService.hasPermission('validate:games') | async) === false"
  >
    <mat-icon>bar_chart</mat-icon>Time-on-Ice Quality</button
  >

  <button
    mat-menu-item
    (click)="showPlayerSummary(game)"
    [disabled]="(authService.hasPermission('read:events') | async) === false"
  >
    <mat-icon>assessment</mat-icon>Player Summary</button
  >
</mat-menu>
