<div class="content container-fluid">
  <div class="row">
    <div class="col-12">
      <app-game-navigation [game]="game"></app-game-navigation>

      <div class="filter" [formGroup]="filter">
        <label>Team</label>
        <select formControlName="team">
          <option [value]="game.homeTeam">{{ game.homeTeam }}</option>
          <option [value]="game.awayTeam">{{ game.awayTeam }}</option>
        </select>

        <label>Period</label>
        <select formControlName="period">
          <option [value]="'1'">Period 1</option>
          <option [value]="'2'">Period 2</option>
          <option [value]="'3'">Period 3</option>
          <option [value]="'4'">OT 1</option>
          <option [value]="'5'">OT 2</option>
          <option [value]="'6'">OT 3</option>
          <option [value]="'7'">OT 4</option>
        </select>

        <div style="display: inline-block; margin-left: 48px">
          <mat-checkbox formControlName="strengthStates"
            >Strength States</mat-checkbox
          >
          <mat-checkbox formControlName="faceOff">Face-Offs</mat-checkbox>
          <mat-checkbox formControlName="shifts">Shifts</mat-checkbox>
          <mat-checkbox formControlName="goals">Goals</mat-checkbox>
          <mat-checkbox formControlName="shots">Shots</mat-checkbox>
          <mat-checkbox formControlName="passes">Passes</mat-checkbox>
          <mat-checkbox formControlName="videoTags">Video Tags</mat-checkbox>
        </div>

        <div style="display: inline-block; margin-left: 48px">
          <mat-checkbox formControlName="toiScores">Shift Scores</mat-checkbox>
          <ng-container *ngIf="filter.value['toiScores']">
            <label>Threshold: </label> {{ filter.value['toiScoreThreshold'] }}%
            <mat-slider [min]="0" [max]="100"
              ><input matSliderThumb formControlName="toiScoreThreshold" />
            </mat-slider>
          </ng-container>

          <mat-checkbox formControlName="shortShifts"
            >Short Shifts ({{ numShortShifts }})</mat-checkbox
          >
          <select formControlName="shortShiftThreshold">
            <option [value]="0">Off</option>
            <option [value]="1">1s</option>
            <option [value]="2">2s</option>
            <option [value]="4">4s</option>
            <option [value]="8">8s</option>
            <option [value]="16">16s</option>
          </select>
        </div>

        <span style="padding-left: 32px">Time-on-Ice Metrics</span>
        <ul class="toi-metrics" *ngIf="qualityCheck">
          <li>Expected: ∑{{ qualityCheck.expected | hhmmss }}</li>
          <li>Actual: ∑{{ qualityCheck.actual | hhmmss }}</li>
          <li class="total-toi-diff" [class.warning]="totalTimeOnIceWarning"
            >Error {{ qualityCheck.error | hhmmss }}</li
          >
        </ul>

        <button
          mat-button
          color="primary"
          (click)="splitShiftsByStrengthState()"
          >Split Shifts by Strength State</button
        >

        <ul class="warnings">
          <li *ngFor="let warning of getCVWarnings()">
            <mat-icon class="warning">warning</mat-icon>
            {{ warning.description }}</li
          >
        </ul>
      </div>

      <div
        *ngIf="selectedShifts[0]?.onEvent?.playerNumber && selectedShiftTracks"
        class="thumbnails"
        [style.top]="'100px'"
      >
        <div style="display: flex">
          <div style="padding: 8px; display: inline-block">
            <h2>{{ selectedShifts[0]?.onEvent?.playerNumber }}</h2>
          </div>
          <button
            mat-icon-button
            [disabled]="
              selectedShiftTrack === 0 || selectedShiftTracks.length === 0
            "
            (click)="selectShiftTrack(selectedShiftTrack - 1)"
          >
            <mat-icon>arrow_back</mat-icon>
          </button>
          <div style="padding-top: 14px" *ngIf="selectedShiftTracks.length > 0"
            >Track {{ selectedShiftTrack + 1 }} of
            {{ selectedShiftTracks?.length }}
          </div>
          <button
            mat-icon-button
            [disabled]="
              selectedShiftTrack === selectedShiftTracks?.length - 1 ||
              selectedShiftTracks.length === 0
            "
            (click)="selectShiftTrack(selectedShiftTrack + 1)"
          >
            <mat-icon>arrow_forward</mat-icon>
          </button>
          <div style="padding-top: 14px" *ngIf="selectedShiftTracks.length > 0"
            >Track Score:
            {{
              selectedShiftTracks[selectedShiftTrack]?.numberDetectionScore *
                100 | number : '1.0-0'
            }}%
          </div>
          <div
            style="padding-top: 14px; padding-left: 8px"
            *ngIf="selectedShiftTracks.length > 0"
            >Video:
            {{
              frameNumberToVideoTime(
                selectedShiftTracks[selectedShiftTrack]?.start
              ) | hhmmss
            }}
            -
            {{
              frameNumberToVideoTime(
                selectedShiftTracks[selectedShiftTrack]?.end
              ) | hhmmss
            }}
            ({{
              frameNumberToVideoTime(
                selectedShiftTracks[selectedShiftTrack]?.end -
                  selectedShiftTracks[selectedShiftTrack]?.start
              ) | mmss
            }})
          </div>
          <div style="flex-grow: 1"></div>
          <button mat-icon-button (click)="hideThumbnails()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <app-player-track-thumbnails [thumbnails]="thumbnails">
        </app-player-track-thumbnails>
        <div style="margin-top: 16px">
          <button
            mat-flat-button
            (click)="hideThumbnails()"
            [disabled]="thumbnails.length === 0"
            >Review Later</button
          >
          <button
            mat-raised-button
            color="accent"
            (click)="confirmShift()"
            [disabled]="thumbnails.length === 0"
            >Confirm Shift</button
          >
        </div>
      </div>

      <div class="layout" style="display: flex">
        <div #timeline style="flex-grow: 1"></div>
        <div style="flex-basis: 300px; margin-left: 8px">
          <app-event-details
            *ngIf="selectedEvent"
            [event]="selectedEvent"
            [game]="game"
            (seek)="seekVideo($event)"
            (save)="saveEvent($event)"
            (delete)="deleteEvent($event)"
          >
          </app-event-details>
          <app-shift-details
            *ngIf="selectedShifts.length === 1"
            [shift]="selectedShifts[0]"
            [game]="game"
            (seek)="seekVideo($event)"
            (saveShift)="saveShift($event)"
            (deleteShift)="deleteShift($event)"
          >
          </app-shift-details>
          <app-multiple-shift-actions
            *ngIf="selectedShifts.length === 2"
            [shifts]="selectedShifts"
            (merge)="mergeShifts($event)"
          ></app-multiple-shift-actions>
        </div>
      </div>
    </div>
  </div>
</div>
