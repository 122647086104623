import { DatePipe, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { LoggerModule } from 'ngx-logger';
import { NgxPaginationModule } from 'ngx-pagination';
import { environment } from '../environments/environment';
import { createErrorHandler } from './common/sentry';
import { CvPipelineDialogComponent } from './cv-pipeline-dialog/cv-pipeline-dialog.component';
import { AppComponent } from './app.component';
import {
  AuthGuardService,
  AuthGuardService as AuthGuard
} from './auth/auth.guard.service';
import { CallbackComponent } from './auth/callback.component';
import { TokenInterceptor } from './auth/http.interceptor';
import { LoginComponent } from './auth/login.component';
import { DuplicateGameDialogComponent } from './duplicate-game-dialog/duplicate-game-dialog.component';
import { EventsComponent } from './events/events.component';
import { GameDetailsComponent } from './game-details/game-details.component';
import { GameEventsComponent } from './game-events/game-events.component';
import { ShotImpactDialogComponent } from './game-events/shot-impact-dialog.component';
import { GameExportDialogComponent } from './game-export-dialog/game-export-dialog.component';
import { GameSyncDialogComponent } from './game-sync-dialog/game-sync-dialog.component';
import { GameValidationDialogComponent } from './game-validation-dialog/game-validation-dialog.component';
import { GameModule } from './game/game.module';
import { GamesComponent } from './games/games.component';
import { ImportSIHFGoalsDialogComponent } from './import-sihf-goals-dialog/import-sihf-goals-dialog.component';
import { LineupChangeDialogComponent } from './lineup-change-dialog/lineup-change-dialog.component';
import { PenaltiesComponent } from './penalties/penalties.component';
import { GameResolver } from './resolvers/game-resolver';
import { ReviewsModule } from './reviews/reviews.module';
import { SeasonSyncDialogComponent } from './season-sync-dialog/season-sync-dialog.component';
import { AlertService } from './services/alert.service';
import { EventValidationService } from './services/event-validation.service';
import { EventService } from './services/event.service';
import { GameTimeService } from './services/game-time.service';
import { GameService } from './services/game.service';
import { LeagueService } from './services/league.service';
import { TrackingService } from './services/tracking.service';
import { PlayerService } from './services/player.service';
import { StrengthStateService } from './services/strength-state.service';
import { TeamService } from './services/team.service';
import { SharedModule } from './shared/shared.module';
import { ShiftsOverviewComponent } from './shifts-overview/shifts-overview.component';
import { TeamInputComponent } from './team-input/team-input.component';
import { TimeOnIceComponent } from './time-on-ice/time-on-ice.component';
import { TimeOnIceModule } from './time-on-ice/time-on-ice.module';
import { UpdateFOINTDialogComponent } from './update-foint-dialog/update-foint-dialog.component';
import { UserComponent } from './user/user.component';
import { VideoDownloadDialogComponent } from './video-download-dialog/video-download-dialog.component';
import { VideoKeyboardActionsComponent } from './video-keyboard-actions/video-keyboard-actions.component';
import { VideoLiveScrubBarComponent } from './video-live-scrub-bar/video-live-scrub-bar.component';
import { VideoObjectTracksComponent } from './video-object-tracks/video-object-tracks.component';
import { VideoPlayerWindowComponent } from './video-player-window/video-player-window.component';
import { VideoTimeOffsetDialogComponent } from './video-time-offset-dialog/video-time-offset-dialog.component';
import { VideoTrackingControlsComponent } from './video-tracking-controls/video-tracking-controls.component';
import { VideoModule } from './video/video.module';
import { CustomPlayerInputComponent } from './custom-player-input/custom-player-input.component';
import { MaskitoModule } from '@maskito/angular';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { LeagueSummaryComponent } from './league-summary/league-summary.component';
import { WebrtcDirective } from './webrtc.directive';
import { RolesGuard } from './auth/roles.guard';
import { VideoService } from './services/video.service';
import { PuckPossessionStateService } from './services/puck-possession-state.service';
import { TeamsComponent } from './teams/teams.component';
import { PlayersComponent } from './players/players.component';
import { ExternalIdsDialogComponent } from './shared/external-ids-dialog/external-ids-dialog.component';
import { EditPlayerDialogComponent } from './players/edit-player-dialog/edit-player-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { EditTeamDialogComponent } from './teams/edit-team-dialog/edit-team-dialog.component';
import { EditLeagueDialogComponent } from './league-summary/edit-league-dialog/edit-league-dialog.component';
import { SeasonService } from './services/season.service';
import { PenaltiesDetailsComponent } from './penalties/penalties-details/penalties-details.component';
import { AllPlayersInputComponent } from './all-players-input/all-players-input.component';
import { VideoPlayerTrimComponent } from './video-player-trim/video-player-trim.component';
import { VideoPlayerTrimControlsComponent } from './video-player-trim/video-player-trim-controls/video-player-trim-controls.component';
import { VideoPlayerTrimDialogComponent } from './video-player-trim/video-player-trim-dialog/video-player-trim-dialog.component';
import { VideoPlayerPreloadComponent } from './video-player-preload/video-player-preload.component';
import { LocalStorageEffects } from './state/effects/root.effects';
import {
  reducer as gameEventReducer,
  name as gameEventFeatureKey
} from './state/reducers/game-event.reducer';
import {
  reducer as gamerReducer,
  name as gameFeatureKey
} from './state/reducers/game.reducer';
import { VisualTimeOnIceComponent } from './visual-time-on-ice/visual-time-on-ice.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { ShiftDetailsComponent } from './shift-details/shift-details.component';
import { MultipleShiftActionsComponent } from './multiple-shift-actions/multiple-shift-actions.component';
import { GameActionMenuComponent } from './game-action-menu/game-action-menu.component';
import { GameQualityReportDialogComponent } from './game-quality-report-dialog/game-quality-report-dialog.component';
import { GameQualityDialogComponent } from './game-quality-dialog/game-quality-dialog.component';
import { TagFormFieldComponent } from './tag-form-field/tag-form-field.component';
import { GameEventShotComponent } from './game-events/game-event-shot/game-event-shot.component';
import { GameEventInterruptionTypeService } from './services/game-event-interruption-type.service';
import { OfficialInputComponent } from './official-input/official-input.component';
import { GameIncidentService } from './services/game-incident.service';
import { VideoKeyboardShortcutComponent } from './video-keyboard-shortcut/video-keyboard-shortcut.component';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    GameEventsComponent,
    GameEventShotComponent,
    GamesComponent,
    VideoPlayerWindowComponent,
    VideoPlayerTrimComponent,
    VideoPlayerTrimControlsComponent,
    VideoPlayerTrimDialogComponent,
    VideoPlayerPreloadComponent,
    EventsComponent,
    CallbackComponent,
    LoginComponent,
    GameDetailsComponent,
    UserComponent,
    ShotImpactDialogComponent,
    PenaltiesComponent,
    PenaltiesDetailsComponent,
    VideoObjectTracksComponent,
    VideoTrackingControlsComponent,
    VideoKeyboardActionsComponent,
    GameExportDialogComponent,
    ShiftsOverviewComponent,
    GameValidationDialogComponent,
    LineupChangeDialogComponent,
    CvPipelineDialogComponent,
    GameSyncDialogComponent,
    SeasonSyncDialogComponent,
    VideoTimeOffsetDialogComponent,
    VideoLiveScrubBarComponent,
    CustomPlayerInputComponent,
    AllPlayersInputComponent,
    VideoDownloadDialogComponent,
    LeagueSummaryComponent,
    TeamsComponent,
    PlayersComponent,
    ExternalIdsDialogComponent,
    EditPlayerDialogComponent,
    WebrtcDirective,
    EditTeamDialogComponent,
    EditLeagueDialogComponent,
    VisualTimeOnIceComponent,
    EventDetailsComponent,
    ShiftDetailsComponent,
    MultipleShiftActionsComponent,
    GameActionMenuComponent,
    GameQualityReportDialogComponent,
    GameQualityDialogComponent,
    DuplicateGameDialogComponent,
    ImportSIHFGoalsDialogComponent,
    TagFormFieldComponent,
    TeamInputComponent,
    OfficialInputComponent,
    UpdateFOINTDialogComponent,
    VideoKeyboardShortcutComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatTabsModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSortModule,
    MatPaginatorModule,
    MatTableModule,
    MatTooltipModule,
    MatExpansionModule,
    MatChipsModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSliderModule,
    TimeOnIceModule,
    GameModule,
    ReviewsModule,
    VideoModule,
    MaskitoModule,
    LoggerModule.forRoot({ level: environment.logLevel }),
    RouterModule.forRoot(
      [
        {
          path: '',
          redirectTo: '/games',
          pathMatch: 'full'
        },
        {
          path: 'leagues',
          component: LeagueSummaryComponent,
          canActivate: [AuthGuard, RolesGuard],
          data: {
            roles: [
              'supervisor',
              'chief_data_officer',
              'process_manager',
              'team_manager',
              'quality_manager'
            ]
          }
        },
        {
          path: 'leagues/:leagueId/teams',
          component: TeamsComponent,
          canActivate: [AuthGuard, RolesGuard],
          data: {
            roles: [
              'supervisor',
              'chief_data_officer',
              'process_manager',
              'team_manager',
              'quality_manager'
            ]
          }
        },
        {
          path: 'leagues/:leagueId/teams/:teamId/players',
          component: PlayersComponent,
          canActivate: [AuthGuard, RolesGuard],
          data: {
            roles: [
              'supervisor',
              'chief_data_officer',
              'process_manager',
              'team_manager',
              'quality_manager'
            ]
          }
        },
        {
          path: 'games',
          component: GamesComponent,
          canActivate: [AuthGuard, RolesGuard],
          data: {
            roles: [
              'supervisor',
              'chief_data_officer',
              'process_manager',
              'team_manager',
              'quality_manager',
              'game_operator',
              'validator',
              'collector'
            ]
          }
        },
        {
          path: 'games/:gameId',
          canActivate: [AuthGuard, RolesGuard],
          data: {
            roles: [
              'supervisor',
              'chief_data_officer',
              'process_manager',
              'team_manager',
              'quality_manager',
              'game_operator',
              'validator',
              'collector'
            ]
          },
          resolve: { game: GameResolver },
          children: [
            {
              path: '',
              component: GameDetailsComponent
            },
            {
              path: 'events',
              component: GameEventsComponent
            },
            {
              path: 'events/:eventId',
              component: GameEventsComponent
            },
            {
              path: 'time-on-ice',
              component: TimeOnIceComponent
            },
            {
              path: 'timeline',
              component: VisualTimeOnIceComponent
            },
            {
              path: 'video-player',
              component: VideoPlayerWindowComponent,
              data: {
                showToolbar: false
              }
            }
          ]
        },
        {
          path: 'users/:username',
          component: UserComponent,
          canActivate: [AuthGuard]
        },
        { path: 'auth0-callback', component: CallbackComponent },
        { path: 'login', component: LoginComponent },
        { path: '**', redirectTo: '' }
      ],
      {
        useHash: false,
        preloadingStrategy: PreloadAllModules
      }
    ),
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: ['localhost:4200'],
        disallowedRoutes: ['localhost:4200/auth/']
      }
    }),
    StoreModule.forRoot({}),
    StoreModule.forFeature(gameEventFeatureKey, gameEventReducer),
    StoreModule.forFeature(gameFeatureKey, gamerReducer),
    StoreDevtoolsModule.instrument({
      name: 'Data',
      logOnly: environment.production
    }),
    EffectsModule.forRoot([LocalStorageEffects])
  ],
  providers: [
    DatePipe,
    EventService,
    EventValidationService,
    GameService,
    LeagueService,
    TeamService,
    PlayerService,
    GameTimeService,
    PuckPossessionStateService,
    StrengthStateService,
    GameResolver,
    AuthGuardService,
    AlertService,
    TrackingService,
    VideoService,
    SeasonService,
    GameEventInterruptionTypeService,
    GameIncidentService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    DecimalPipe,
    {
      provide: 'Window',
      useValue: window
    },
    {
      provide: Auth0Client,
      useValue: new Auth0Client({
        domain: environment.AUTH0_DOMAIN_SPA,
        client_id: environment.AUTH0_CLIENT_ID_SPA,
        audience: 'https://data-collection.49ing.ch/v1/',
        responseType: 'token id_token',
        redirect_uri: window.location.origin + '/auth0-callback',
        scope:
          'openid profile read:teams read:players read:games write:games read:events write:events',
        cacheLocation: 'localstorage',
        useRefreshTokens: true
      })
    },
    {
      provide: ErrorHandler,
      useFactory: createErrorHandler,
      deps: [AlertService]
    }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
