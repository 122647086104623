import { Game } from '../domain/game';

export const collectionOptions = [
  {
    id: 'is_live_collection',
    fieldName: 'isLiveCollection',
    label: 'Live Collection',
    tooltip:
      'Optimizes FOINT/Shot event types for fast, uninterrupted creation of draft events, requires 2nd-pass'
  },
  {
    id: 'is_highlight_collection',
    fieldName: 'isHighlightCollection',
    label: 'Highlight collection',
    tooltip: 'Enables highlight events and rating column'
  },
  {
    id: 'is_live_draft_events',
    fieldName: 'isLiveDraftEvents',
    label: 'Realtime Collection',
    tooltip:
      'Optimizes FOINT/Shot event types for fast, uninterrupted creation of draft events, requires 2nd-pass'
  },
  {
    id: 'auto_puck_possession_events',
    fieldName: 'autoPuckPossessionEvents',
    label: 'Automated Neutral Puck Possession',
    tooltip:
      'Automatically adds neutral puck possession events directly after face-offs, shots and passes (chip, rim, incomplete)',
    condition: (game: Game) =>
      !game.dataSet || (game.dataSet !== 'LITE' && game.dataSet !== 'LITE_PLUS')
  },
  {
    id: 'goal_clips',
    fieldName: 'goalClips',
    label: 'Goal Clips',
    tooltip: 'Requires enriching goals with slow motion sequences'
  },
  {
    id: 'is_assists_collection',
    fieldName: 'isAssistsCollection',
    label: 'Assists',
    tooltip: 'Requires enriching goals with assists'
  },
  {
    id: 'is_sihf_live',
    fieldName: 'isSihfLive',
    label: 'SIHF Live Export',
    tooltip: 'Exports data to SIHF Game Center in realtime'
  },
  {
    id: 'disable_updates',
    fieldName: 'disableUpdates',
    label: 'Disable Automated SIHF Updates',
    tooltip: 'Prevents SIHF game lineup from being overridden',
    condition: (game: Game) => game.idType === 'SIHF' && !!game.gameId
  },
  {
    id: 'is_training_game',
    fieldName: 'isTrainingGame',
    label: 'Training Game',
    tooltip: 'Data is not exported (Data Cockpit, SIHF, others)'
  },
  {
    id: 'lagClock',
    fieldName: 'lagClock',
    label: 'Show Lag Clock',
    tooltip: 'Shows a clock with difference to live in the video view'
  },
  {
    id: 'gameIncidentCollection',
    fieldName: 'isGameIncidentCollection',
    label: 'Game Incident Collection',
    tooltip:
      'Enables collection of game incident events; scripted video-tag playlists are created to review those events after the game'
  },
  {
    id: 'videoReview',
    fieldName: 'isVideoReview',
    label: 'Video Review',
    tooltip: 'Enables game in VJ360'
  },
  {
    id: 'officiatingCollection',
    fieldName: 'isOfficiatingCollection',
    label: 'Officiating Collection',
    tooltip: 'Enables collection and validation of officiating events'
  },
  {
    id: 'gameTimeImport',
    fieldName: 'isGameTimeImport',
    label: 'Import SIHF GameTime',
    tooltip: 'Enables import of SIHF game time as period start/end events',
    condition: (game: Game) => game.idType === 'SIHF' && !!game.gameId
  }
];
