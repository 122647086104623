import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, interval, Subject, timer } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  switchMap,
  takeUntil
} from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import {
  AggregationStatus,
  FinalizationStatus,
  Game,
  GameStatus,
  SeasonType
} from '../domain/game';
import { League } from '../domain/league';
import { SeasonSyncDialogComponent } from '../season-sync-dialog/season-sync-dialog.component';
import { AlertService } from '../services/alert.service';
import { GameService } from '../services/game.service';
import { LeagueService } from '../services/league.service';
import { SeasonService } from '../services/season.service';
import { SeasonCollectionEffortComponent } from '../shared/season-collection-effort/season-collection-effort.component';
import { StorageService } from '../storage.service';

const FILTERS_KEY = 'games-filter';
const SORT_FIELD_KEY = 'games-sort-field';
const SORT_DIRECTION_KEY = 'games-sort-order';
const COLUMNS_KEY = 'games-page-columns';
const INITIAL_PAGE_SIZE = 10;
const PAGE_SIZE_OPTIONS: number[] = [10, 20, 50, 100];

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.css']
})
export class GamesComponent implements OnInit, OnDestroy {
  readonly gameStatus = GameStatus;
  readonly gameStatusOptions = Object.values(GameStatus);
  readonly seasonTypes = Object.values(SeasonType);
  readonly aggregationStatus = AggregationStatus;
  readonly finalizationStatus = FinalizationStatus;
  readonly currentDate = new Date();
  readonly pageSizeOptions: number[] = PAGE_SIZE_OPTIONS;

  exporting: boolean;
  loading: boolean;

  @Input()
  initialFilter: any;
  filterForm: FormGroup<{
    date: FormControl<string | null>;
    seasonType: FormControl<string | null>;
    awayTeam: FormControl<string | null>;
    homeTeam: FormControl<string | null>;
    league: FormControl<string | null>;
    flags: FormControl<string | null>;
    season: FormControl<string | null>;
    eventCollectorName: FormControl<string | null>;
    dataSet: FormControl<string | null>;
    tags: FormControl<string | null>;
    status: FormControl<string | null>;
  }>;
  today: UntypedFormControl;

  pageIndex = 0;
  pageSize = INITIAL_PAGE_SIZE;
  sortField = 'date';
  sortDirection: SortDirection = 'asc';

  columns = [
    'season',
    'seasonType',
    'league',
    'date',
    'venueLocalDate',
    'teams',
    'score',
    'flags',
    'dataSet',
    'eventCollectorName',
    'tags',
    'progress',
    'trackingStats',
    'insertDate',
    'lastSync',
    'status',
    'actions'
  ];
  displayedColumns = [];

  dataSource: MatTableDataSource<Game>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  gameCount = 0;
  leagues: League[] = [];
  leaguesById = {};

  seasons = this.seasonService.getSeasons();

  endPoll$ = new Subject();

  constructor(
    private gameService: GameService,
    private leagueService: LeagueService,
    private seasonService: SeasonService,
    private alertService: AlertService,
    public authService: AuthService,
    private storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe
  ) {
    this.filterForm = new FormGroup({
      date: new FormControl<string>(undefined),
      season: new FormControl<string>(undefined),
      seasonType: new FormControl<string>(undefined),
      league: new FormControl<string>(undefined),
      homeTeam: new FormControl<string>(undefined),
      awayTeam: new FormControl<string>(undefined),
      flags: new FormControl<string>(undefined),
      dataSet: new FormControl<string>(undefined),
      eventCollectorName: new FormControl<string>(undefined),
      tags: new FormControl<string>(undefined),
      status: new FormControl<string>(undefined)
    });
    this.today = this.formBuilder.control([undefined]);
  }

  async ngOnInit() {
    this.dataSource = new MatTableDataSource<Game>([]);
    this.displayedColumns = this.storageService.getItem(COLUMNS_KEY) || [
      ...this.columns
    ];
    this.today.valueChanges.subscribe((checked) => {
      if (checked) {
        this.filterForm.controls['date'].disable();
      } else {
        this.filterForm.controls['date'].enable();
      }
    });

    this.restoreFilters();
    this.leagueService.getLeagues().subscribe((leagues) => {
      this.leagues = leagues;
      leagues.forEach((l) => (this.leaguesById[l.sihfId] = l.shortName));
    });
    this.filterForm.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(() => this.filterChanged(true))
      )
      .subscribe();
    interval(60 * 1000)
      .pipe(takeUntil(this.endPoll$), takeUntil(timer(8 * 3600 * 1000)))
      .subscribe(
        () => this.loadGames(),
        () => this.endPoll$.next(EMPTY),
        () => console.log('stopped polling')
      );
    await this.loadGames();
  }

  ngOnDestroy() {
    this.endPoll$.next(EMPTY);
  }

  private async filterChanged(resetPage: boolean = false) {
    if (resetPage) {
      this.pageIndex = 0;
    }
    await this.persistFilters();
    await this.loadGames();
  }

  async pageRequested(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    await this.persistFilters();
    await this.loadGames();
  }

  async sortingChanged(sort: Sort) {
    this.sortField = sort.active;
    this.sortDirection = sort.direction;
    await this.persistFilters();
    await this.loadGames();
  }

  async loadGames() {
    try {
      console.log('loadGames...');
      this.loading = true;
      const filterValue = { ...this.filterForm.value };
      if (this.today.value) {
        filterValue.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      }
      const data = await this.gameService
        .findGames(
          filterValue,
          this.pageIndex,
          this.pageSize,
          this.sortField,
          this.sortDirection
        )
        .pipe(finalize(() => (this.loading = false)))
        .toPromise();
      this.gameCount = data[0];
      this.dataSource.data = data[1];
    } catch (error) {
      console.log('loading games failed', error);
      this.dataSource.data = [];
      this.alertService.showError('Could not load games: ' + error.message);
    }
  }

  showAggregatedCollectionEffort() {
    const { league, season, seasonType } = this.filterForm.value;
    this.dialog.open(SeasonCollectionEffortComponent, {
      width: '900px',
      data: {
        league,
        season,
        seasonType
      }
    });
  }

  syncSeason() {
    const dialog = this.dialog.open(SeasonSyncDialogComponent, {
      width: '400px',
      data: { leagues: this.leagues }
    });
    dialog.afterClosed().subscribe(([season, league]) => {
      if (!season || !league) {
        return;
      }

      this.exporting = true;
      this.gameService
        .syncSeason(season, league)
        .pipe(finalize(() => (this.exporting = false)))
        .subscribe(
          async (res) => {
            console.log('Sync season succeeded');
            await this.filterChanged();
            if (res.message) {
              alert(res.message);
            }
          },
          (err) => {
            alert('Sync season failed: ' + err.error.message);
          }
        );
    });
  }

  private async persistFilters() {
    if (!this.initialFilter) {
      const filter = { ...this.filterForm.value, today: '' + this.today.value };
      this.storageService.setItem(FILTERS_KEY, filter);
      this.storageService.setItem(SORT_FIELD_KEY, this.sortField);
      this.storageService.setItem(SORT_DIRECTION_KEY, this.sortDirection);
      await this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          ...filter,
          page: this.pageIndex,
          pageSize: this.pageSize
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  restoreFilters(): void {
    if (this.initialFilter) {
      this.filterForm.patchValue(this.initialFilter);
    } else {
      this.sortField = this.storageService.getItem(SORT_FIELD_KEY);
      this.sortDirection = this.storageService.getItem(SORT_DIRECTION_KEY);
      this.pageIndex = this.route.snapshot.queryParams.page
        ? +this.route.snapshot.queryParams.page
        : 0;
      this.pageSize = this.route.snapshot.queryParams.pageSize
        ? +this.route.snapshot.queryParams.pageSize
        : INITIAL_PAGE_SIZE;
      const initialFilterState = {
        ...this.storageService.getItem(FILTERS_KEY),
        ...this.route.snapshot.queryParams
      };

      // migrate existing filter state
      if (
        initialFilterState.league &&
        !Array.isArray(initialFilterState.league)
      ) {
        initialFilterState.league = [initialFilterState.league];
      }
      this.filterForm.patchValue(initialFilterState);
      if (initialFilterState.today) {
        this.today.patchValue(initialFilterState.today === 'true');
      }
    }
  }
  eventStatsProgressSeconds(game: Game, eventTypes: string[]) {
    if (!game.eventStats) {
      return 0;
    }
    const seconds = game.eventStats
      .filter((s) => eventTypes.includes(s._id.eventType))
      .map((s) => s.end)
      .reduce((prev, cur) => Math.max(prev, cur), 0);
    return seconds;
  }

  eventStatsCount(game: Game, eventTypes: string[]) {
    if (!game.eventStats) {
      return 0;
    }
    return game.eventStats
      .filter((s) => eventTypes.includes(s._id.eventType))
      .reduce((prev, cur) => prev + cur.count, 0);
  }

  eventStatsProgress(game: Game, eventTypes: string[]) {
    if (!game.eventStats) {
      return 0;
    }
    return game.eventStats
      .filter((s) => eventTypes.includes(s._id.eventType))
      .map((s) => (s.end / 3600) * 100)
      .reduce((prev, cur) => Math.max(prev, cur), 0);
  }

  lastSyncDate(game: Game) {
    if (game.auditLog) {
      const syncs = game.auditLog.filter((l) => l.action === 'sync');
      if (syncs.length > 0) {
        return syncs[syncs.length - 1].date.substr(0, 19);
      }
    }
  }

  resetFilter() {
    this.pageIndex = 0;
    this.pageSize = 10;
    this.sortField = 'date';
    this.sortDirection = 'desc';
    this.filterForm.reset();
    this.today.reset();
  }

  toggleColumnVisibility(column: string) {
    if (this.displayedColumns.includes(column)) {
      this.displayedColumns.splice(this.displayedColumns.indexOf(column), 1);
    } else {
      this.displayedColumns.push(column);
    }
    this.storageService.setItem(COLUMNS_KEY, this.displayedColumns);
  }

  get headerColumns() {
    return this.bodyColumns.map((c) => c + '-filter');
  }

  get bodyColumns() {
    return this.columns.filter((c) => this.displayedColumns.includes(c));
  }

  async gameUpdated() {
    await this.loadGames();
  }

  hasCVErrors(game: Game) {
    return game.getCVFailures().length > 0;
  }

  hasCVWarnings(game: Game) {
    return game.getCVWarnings().length > 0;
  }

  combineCVErrorsAndWarnings(game: Game) {
    return (
      game.getCVFailures().length +
      ' errors, ' +
      game.getCVWarnings().length +
      ' warnings '
    );
  }

  tagsChange(tags: string[]) {
    this.filterForm.patchValue({ tags: tags.join(',') });
  }

  selectGameTag(value: string): void {
    const tags = this.filterForm.value.tags ?? '';
    if (tags.includes(value)) {
      return;
    }
    const value2 = tags.split(',').filter((t) => t);
    value2.push(value);
    this.filterForm.patchValue({ tags: value2.join(',') });
  }

  goalsWithPriority(
    goalsFromSetup: number | null | undefined,
    goalsFromEvents: number
  ): number {
    return goalsFromSetup ?? goalsFromEvents ?? 0;
  }

  requiresTimeSync(game: Game) {
    if (game.isCameraTimeSynchronized) {
      return false;
    }
    return game.videos.filter((v) => v.format === 'hls').length >= 2;
  }

  async navigateToGameActions(game: Game, params: any) {
    return this.router.navigate(['games', game._id, 'events'], {
      queryParams: params
    });
  }
}
